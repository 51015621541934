@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

* {
  font-family: Lato, serif !important; }

body {
  display: block;
  min-width: 100%;
  width: auto;
  background: #f6f6f6; }

.hidden-mobile {
  display: none; }
  @media (min-width: 1075px) {
    .hidden-mobile {
      display: block; } }

@media (min-width: 1075px) {
  .hidden-desktop {
    display: none; } }

.wrapper {
  min-width: 100%;
  width: 100%;
  overflow: hidden; }

.container {
  max-width: 500px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 1px 42px 0;
  margin: -1px auto 0; }
  @media (min-width: 1075px) {
    .container {
      min-width: auto;
      max-width: 1140px;
      padding: -1px 18px 0 18px; } }

.btn-orcamento {
  display: block;
  width: 400px;
  height: 50px;
  background: #fff;
  border: 0;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  color: #e35060;
  font-size: 21px;
  font-weight: 900;
  outline: none; }

.header {
  width: 100%;
  height: 1180px;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #000; }
  .header.barras {
    background-image: url(/static/media/header_background_mobile.ba329b0a.png); }
  .header.blanques {
    background-image: url(/static/media/header_background_mobile.056fa319.png); }
  .header.bobinas {
    background-image: url(/static/media/header_background_mobile.84382357.png); }
  .header.cantoneiras {
    height: 1130px;
    background-image: url(/static/media/header_background_mobile.f6dded58.png); }
  .header.chapa {
    background-image: url(/static/media/header_background_mobile.d321cf4f.png); }
  .header.chapa-grossa {
    background-image: url(/static/media/header_background_mobile.3ff12fbe.png); }
  .header.fita-tiras {
    height: 1220px;
    background-image: url(/static/media/header_background_mobile.e7dfd668.png); }
  .header.tubos-com-costura {
    background-image: url(/static/media/header_background_mobile.9604d53e.png); }
  .header.tubos-sem-costura {
    height: 1278px;
    background-image: url(/static/media/header_background_mobile.916d04e2.png); }
  @media (min-width: 1075px) {
    .header {
      height: 934px !important;
      padding-top: 1px;
      margin-top: -1px;
      background-size: auto; }
      .header.barras {
        background-image: url(/static/media/header_background.d09b29a2.png); }
      .header.blanques {
        background-image: url(/static/media/header_background.a89932d3.png); }
      .header.bobinas {
        background-image: url(/static/media/header_background.5393caec.png); }
      .header.cantoneiras {
        background-image: url(/static/media/header_background.fa5d455b.png); }
      .header.chapa {
        background-image: url(/static/media/header_background.cb90546e.png); }
      .header.chapa-grossa {
        background-image: url(/static/media/header_background.e05b742b.png); }
      .header.fita-tiras {
        background-image: url(/static/media/header_background.27d42f9a.png); }
      .header.tubos-com-costura {
        background-image: url(/static/media/header_background.1bd977ce.png); }
      .header.tubos-sem-costura {
        background-image: url(/static/media/header_background.640d377a.png); } }
  .header .logo {
    width: 166px;
    height: 118px;
    position: absolute;
    top: 53px;
    left: 50px;
    z-index: 1000; }
    .header .logo img {
      width: 100%; }
    .header .logo:after {
      content: '';
      width: 354px;
      height: 3px;
      display: block;
      background: #fff;
      position: absolute;
      top: 58px;
      left: 195px; }
  .header .product-image {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat; }
    .header .product-image.barras {
      width: 490px;
      height: 328px;
      top: 135px;
      left: -10px;
      background-image: url(/static/media/header_product.c28c28b0.png); }
    .header .product-image.blanques {
      width: 490px;
      height: 373px;
      top: 120px;
      left: 0;
      background-image: url(/static/media/header_product.6a7fcbd5.png); }
    .header .product-image.bobinas {
      width: 396px;
      height: 534px;
      top: -30px;
      left: 55px;
      background-image: url(/static/media/header_product.9016e36e.png); }
    .header .product-image.cantoneiras {
      width: 502px;
      height: 230px;
      top: 100px;
      left: 0;
      background-image: url(/static/media/header_product.0b60fad4.png); }
    .header .product-image.chapa {
      width: 701px;
      height: 431px;
      top: 90px;
      left: -140px;
      background-image: url(/static/media/header_product.febbd8bf.png); }
    .header .product-image.chapa-grossa {
      width: 701px;
      height: 542px;
      top: -40px;
      left: -105px;
      background-image: url(/static/media/header_product.9bc005d5.png); }
    .header .product-image.fita-tiras {
      width: 441px;
      height: 409px;
      top: 105px;
      left: 28px;
      background-image: url(/static/media/header_product_mobile.49f8d767.png); }
    .header .product-image.tubos-com-costura {
      width: 519px;
      height: 368px;
      top: 105px;
      left: -2px;
      background-image: url(/static/media/header_product_mobile.83780a7f.png); }
    .header .product-image.tubos-sem-costura {
      width: 501px;
      height: 346px;
      top: 92px;
      left: -2px;
      background-image: url(/static/media/header_product_mobile.c1c8f772.png); }
    @media (min-width: 1075px) {
      .header .product-image.barras {
        width: 932px;
        height: 624px;
        top: 195px;
        left: -390px; }
      .header .product-image.blanques {
        width: 1072px;
        height: 817px;
        top: 132px;
        left: -390px; }
      .header .product-image.bobinas {
        width: 606px;
        height: 818px;
        top: 0;
        left: -50px; }
      .header .product-image.cantoneiras {
        width: 1028px;
        height: 471px;
        top: 280px;
        left: -390px; }
      .header .product-image.chapa {
        width: 1182px;
        height: 727px;
        top: 150px;
        left: -390px; }
      .header .product-image.chapa-grossa {
        width: 1187px;
        height: 918px;
        top: 40px;
        left: -391px; }
      .header .product-image.fita-tiras {
        width: 900px;
        height: 702px;
        top: 232px;
        left: -200px;
        background-image: url(/static/media/header_product.53af3ed2.png); }
      .header .product-image.tubos-com-costura {
        width: 1071px;
        height: 994px;
        top: 198px;
        left: -391px;
        background-image: url(/static/media/header_product.dfa52dde.png); }
      .header .product-image.tubos-sem-costura {
        width: 1071px;
        height: 994px;
        top: 5px;
        left: -390px;
        background-image: url(/static/media/header_product.795e6d55.png); } }
  .header .wrapper-box {
    width: 100%; }
    @media (min-width: 1075px) {
      .header .wrapper-box {
        width: 400px;
        float: right; } }
  .header .product-info-box {
    width: 400px;
    color: #fff;
    text-align: justify;
    font-size: 21px;
    line-height: 32px;
    margin: 495px 0 0 0;
    padding-bottom: 35px; }
    .header .product-info-box.cantoneiras {
      margin: 350px 0 0 0; }
      @media (min-width: 1075px) {
        .header .product-info-box.cantoneiras {
          margin: 270px 0 0 0; } }
    .header .product-info-box.chapa-grossa {
      margin: 430px 0 0 0; }
      @media (min-width: 1075px) {
        .header .product-info-box.chapa-grossa {
          margin: 250px 0 0 0; } }
    .header .product-info-box.fita-tiras {
      margin: 530px 0 0 0; }
      @media (min-width: 1075px) {
        .header .product-info-box.fita-tiras {
          margin: 265px 0 0 0; } }
    .header .product-info-box.tubos-com-costura {
      margin: 450px 0 0 0; }
      @media (min-width: 1075px) {
        .header .product-info-box.tubos-com-costura {
          margin: 240px 0 0 0; } }
    .header .product-info-box.tubos-sem-costura {
      margin: 450px 0 0 0; }
      @media (min-width: 1075px) {
        .header .product-info-box.tubos-sem-costura {
          font-size: 19px;
          margin: 210px 0 0 0; } }
    @media (min-width: 1075px) {
      .header .product-info-box {
        font-size: 19px;
        line-height: 27px;
        margin-top: 256px; }
        .header .product-info-box .wrapper-btn-orcamento {
          display: block;
          width: 400px;
          height: 60px; }
        .header .product-info-box .btn-orcamento {
          width: 300px;
          float: right;
          font-size: 18px;
          letter-spacing: 1px; } }
    .header .product-info-box .wrapper-btn-orcamento {
      width: 100%; }
    .header .product-info-box h1 {
      font-weight: 900;
      font-size: 96px;
      letter-spacing: 3px;
      line-height: 60px;
      text-transform: uppercase;
      margin-bottom: 10px;
      text-align: justify; }
      .header .product-info-box h1.barras {
        font-size: 96px; }
      .header .product-info-box h1.blanques {
        font-size: 80px; }
      .header .product-info-box h1.bobinas {
        font-size: 84px;
        padding-top: 50px; }
        @media (min-width: 1075px) {
          .header .product-info-box h1.bobinas {
            padding-top: 0; } }
      .header .product-info-box h1.cantoneiras {
        font-size: 51px; }
      .header .product-info-box h1.chapa-grossa {
        font-size: 93px; }
        .header .product-info-box h1.chapa-grossa .second {
          font-size: 82px;
          padding-top: 20px;
          display: inline-block; }
        .header .product-info-box h1.chapa-grossa .last {
          line-height: 30px !important; }
      .header .product-info-box h1.tubos-com-costura {
        font-size: 63px; }
        .header .product-info-box h1.tubos-com-costura .second {
          font-size: 80px;
          padding-top: 14px;
          display: inline-block; }
        .header .product-info-box h1.tubos-com-costura .last {
          line-height: 30px !important; }
      .header .product-info-box h1.tubos-sem-costura {
        font-size: 64px; }
        .header .product-info-box h1.tubos-sem-costura .second {
          font-size: 80px;
          padding-top: 14px;
          display: inline-block; }
        .header .product-info-box h1.tubos-sem-costura .last {
          line-height: 30px !important; }
      .header .product-info-box h1.fita-tiras {
        font-size: 66px; }
      .header .product-info-box h1 span {
        margin: 5px 0 0 0;
        font-size: 56px; }
    .header .product-info-box p .btn-orcamento {
      margin: 20px 0 0 0; }
  .header .contact-box {
    width: 400px;
    color: #fff;
    text-align: justify;
    line-height: 32px; }
    .header .contact-box > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap; }
      .header .contact-box > div p {
        font-size: 25px;
        width: 200px;
        flex-direction: column;
        text-align: left;
        padding-top: 15px; }
        .header .contact-box > div p:first-child {
          font-size: 25px;
          width: 400px;
          padding-top: 8px; }
        @media (min-width: 1075px) {
          .header .contact-box > div p {
            padding-top: 0;
            line-height: 30px; } }
        @media (min-width: 1075px) {
          .header .contact-box > div p:nth-child(2) {
            font-size: 22px;
            height: 65px;
            border-right: 1px solid #fff;
            margin-top: 18px; } }
        .header .contact-box > div p:nth-child(3) {
          padding-left: 25px; }
          @media (min-width: 1075px) {
            .header .contact-box > div p:nth-child(3) {
              font-size: 22px;
              margin-top: 18px; } }
    .header .contact-box .title {
      font-size: 33px;
      font-weight: bold;
      border-bottom: 2px solid #fff;
      padding: 0 0 10px 0; }

.nav-toggle {
  width: 57px;
  height: 48px;
  border: 0;
  position: absolute;
  left: 45px;
  top: 45px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAAwAgMAAADWeTreAAAACVBMVEVMaXH////////OZTV/AAAAAnRSTlMAgJsrThgAAAAhSURBVHjaYwhFAQ4Mq1BAA9356O4Z7GCgwwudPxpeJPEBfCsIlMdPbXkAAAAASUVORK5CYII=) no-repeat;
  outline: none;
  cursor: pointer; }

.menu {
  width: 100%;
  background: #fff;
  position: absolute;
  top: 130px;
  left: 0;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.5);
  height: 0;
  -webkit-transition: height .3s ease-in-out;
  transition: height .3s ease-in-out;
  overflow: hidden;
  z-index: 1000;
  padding: 0; }
  @media (min-width: 1075px) {
    .menu {
      height: auto;
      overflow: hidden;
      background: none;
      box-shadow: none;
      top: 100px; } }
  .menu .container {
    width: 100%;
    max-width: none;
    padding: 0; }
    @media (min-width: 1075px) {
      .menu .container {
        min-width: auto;
        max-width: 1140px;
        padding: -1px 18px 0 18px; } }
  .menu.open {
    height: 256px; }
  .menu ul {
    width: 100%;
    list-style: none;
    padding: 30px 0; }
    @media (min-width: 1075px) {
      .menu ul {
        width: 500px;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        float: right;
        margin-right: -10px; } }
    .menu ul li {
      width: 100%;
      height: 50px;
      text-align: center; }
      @media (min-width: 1075px) {
        .menu ul li {
          height: auto;
          width: auto;
          border-right: 2px solid #fff; }
          .menu ul li:last-child {
            border: none; } }
      .menu ul li:hover {
        background: #e35060; }
        .menu ul li:hover a {
          color: #fff; }
        @media (min-width: 1075px) {
          .menu ul li:hover {
            background: none; } }
      .menu ul li a {
        width: 100%;
        height: 100%;
        display: block;
        font-size: 20px;
        color: #e35060;
        line-height: 49px;
        font-weight: bold;
        text-decoration: none;
        text-transform: uppercase; }
        @media (min-width: 1075px) {
          .menu ul li a {
            color: #fff;
            text-transform: none;
            line-height: normal;
            padding: 0 15px;
            font-size: 21px; } }

.sections {
  width: 100%; }

.about {
  position: relative;
  padding-bottom: 40px; }
  @media (min-width: 1075px) {
    .about .container .box {
      width: 432px; } }
  .about .container {
    text-align: center; }
  .about .logo {
    padding: 70px 0 40px 0; }
  .about .text {
    text-align: justify;
    font-size: 24px;
    line-height: 44px;
    color: #676767; }
    @media (min-width: 1075px) {
      .about .text {
        font-size: 18px;
        line-height: 32px; } }
  .about .about-img-desktop {
    display: none;
    width: 959px;
    height: 987px;
    background: url(/static/media/about_image_desktop.6269ff6c.png) no-repeat center;
    position: absolute;
    top: -92px;
    right: -390px;
    z-index: -1; }
    @media (min-width: 1075px) {
      .about .about-img-desktop {
        display: block; } }
  .about .about-img {
    width: 100%;
    height: 444px;
    background: url(/static/media/about_image.c7c14ae4.png) no-repeat center;
    margin-top: 40px; }
  .about .quality-certification {
    width: 100%; }
    @media (min-width: 1075px) {
      .about .quality-certification {
        margin: 47px 0 0 0; } }
    .about .quality-certification a {
      display: block;
      margin: 65px 0 50px 0; }
      .about .quality-certification a img {
        width: 100%;
        max-width: 259px; }
      @media (min-width: 1075px) {
        .about .quality-certification a {
          width: 160px;
          align-self: center;
          margin: -50px 0 0 0; } }
    @media (min-width: 1075px) {
      .about .quality-certification .box {
        display: flex;
        justify-content: space-between; } }
    @media (min-width: 1075px) {
      .about .quality-certification .text {
        width: 230px;
        justify-self: right; } }
  .about .our-units {
    margin-top: 90px; }
    @media (min-width: 1075px) {
      .about .our-units {
        margin-top: 110px; } }
    .about .our-units h3 {
      color: #e35060;
      font-size: 26px;
      margin-bottom: 87px; }
      @media (min-width: 1075px) {
        .about .our-units h3 {
          font-size: 36px;
          margin-bottom: 55px; } }
    @media (min-width: 1075px) {
      .about .our-units .units {
        display: flex;
        justify-content: space-between; } }
    @media (min-width: 1075px) {
      .about .our-units .units .unit {
        padding-right: 10px; } }
    .about .our-units .units .unit .title {
      color: #e35060;
      font-size: 32px;
      font-weight: bold;
      border-bottom: 2px solid #bdbdbd;
      padding-bottom: 22px;
      margin-bottom: 22px;
      padding-left: 30px;
      position: relative;
      text-align: left; }
      @media (min-width: 1075px) {
        .about .our-units .units .unit .title {
          font-size: 18px;
          padding-left: 15px;
          padding-bottom: 18px;
          margin-bottom: 25px; } }
      .about .our-units .units .unit .title::before {
        content: '';
        width: 22px;
        height: 27px;
        display: block;
        position: absolute;
        left: 0px;
        top: 3px;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAbCAMAAABP9I/XAAAA21BMVEVMaXHDS0u2QUHCS0u6RES7RUXVW1vNVFTETU3ZXl7QV1e/SUnaX1/SWFjBSkq4Q0POVVXKUVHaX1/IUFDCS0vXXFzOVVW9R0fPVlbZXl7TWVm6RES2QUG4Q0PNVFTETU27RUXKUlLCS0vDTEzTWVnGTk7PVlbZXl7IUFC/SUnSWFjJUVHBSkq6RETLU1PVW1vNVFTMVFTXXFy9R0e4Q0O4Q0O6RES7RUW9R0e/SUnBSkrDS0vETU3GTk7IUFDKUVHLU1PNVFTPVlbRV1fTWVnUWlrWXFzYXV3aX19lygG7AAAANXRSTlMAQEBAQEBAQEBAQEBAQEBAQICAgICAgICAgICAgICAgL+/v7+/v7+/v7+/v7+/v7+/v7+/v3P3n+0AAAC+SURBVHjabdLVEsMgEAXQTd3dLXV316Sa9v+/qKEbiiT3iTmzAwsLWEnUGrrWURXgU3rcb1ddu5xP4zDT9vPPx8OK6uzF876OujRE3pWJBt8ybwn3KCfTlKsmf5DnpKSFvAFIIXfxnKbEVlMZZJfEgFyR2E2rI8gFYW/WiZ/rZG0ui1bfU9Z3nJTYbjn57ZeV2YPnGI4vCD6RvY5jyLPxDBkPuFlGGQszDlGOgZAcsgJSFoQDYAv5PuCQUZ+tv0mNUKV/lu7JAAAAAElFTkSuQmCC); }
        @media (min-width: 1075px) {
          .about .our-units .units .unit .title::before {
            width: 11px;
            height: 15px;
            top: 1px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAPCAMAAAAF48UCAAAAQlBMVEVHcEzXW1vCSkq9Rka+RkbWW1vVWlrPVVXFTEzKUVG6RETASUnKUVHHTk7DTEzUWVnNVFTRVlbXXFy9RkbaX1+6Q0O0WQ6UAAAAC3RSTlMAv4C/QECAv7+AgCD7gQIAAABVSURBVAjXVc5LDoAwCEXRq/ar9a/736qU0Ca+ATkD8gDw8ShrcgDDfoq3ZQJ/mW/HU52rXzXQHIDRHIHUdmJOuhN6z/zrx5v1cFCPaPQfLLF0Qtb5AXAEBgYVZ9QRAAAAAElFTkSuQmCC); } }
    .about .our-units .units .unit .address {
      color: #878787;
      font-size: 20px;
      letter-spacing: 1px;
      line-height: 37px;
      text-align: left;
      margin-bottom: 50px; }
      @media (min-width: 1075px) {
        .about .our-units .units .unit .address {
          font-size: 13px;
          letter-spacing: normal;
          line-height: 25px; } }

.contact {
  width: 100%;
  height: 1210px;
  background-size: cover;
  color: #fff;
  padding-top: 60px;
  text-align: center; }
  .contact.barras {
    background: #000 url(/static/media/contact_background_mobile.f17b75b4.png) no-repeat center; }
  .contact.blanques {
    background: #000 url(/static/media/contact_background_mobile.03723bab.png) no-repeat center; }
  .contact.bobinas {
    background: #000 url(/static/media/contact_background_mobile.a1b30500.png) no-repeat center; }
  .contact.cantoneiras {
    background: #000 url(/static/media/contact_background_mobile.bdfb083d.png) no-repeat center; }
  .contact.chapa {
    background: #000 url(/static/media/contact_background_mobile.8df4ed9f.png) no-repeat center; }
  .contact.chapa-grossa {
    background: #000 url(/static/media/contact_background_mobile.e1d40178.png) no-repeat center; }
  .contact.fita-tiras {
    background: #000 url(/static/media/contact_background_mobile.7f051432.png) no-repeat center; }
  .contact.tubos-com-costura {
    background: #000 url(/static/media/contact_background_mobile.3de10b84.png) no-repeat center; }
  .contact.tubos-sem-costura {
    background: #000 url(/static/media/contact_background_mobile.6ead09d5.png) no-repeat center; }
  @media (min-width: 1075px) {
    .contact {
      height: 1082px;
      padding-top: 155px; }
      .contact.barras {
        background: #000 url(/static/media/contact_background.ae796e61.png) no-repeat center; }
      .contact.blanques {
        background: #000 url(/static/media/contact_background.0089061d.png) no-repeat center; }
      .contact.bobinas {
        background: #000 url(/static/media/contact_background.0928a456.png) no-repeat center; }
      .contact.cantoneiras {
        background: #000 url(/static/media/contact_background.b03a9c4e.png) no-repeat center; }
      .contact.chapa {
        background: #000 url(/static/media/contact_background.23161444.png) no-repeat center; }
      .contact.chapa-grossa {
        background: #000 url(/static/media/contact_background.954cbf40.png) no-repeat center; }
      .contact.fita-tiras {
        background: #000 url(/static/media/contact_background.656eb94f.png) no-repeat center; }
      .contact.tubos-com-costura {
        background: #000 url(/static/media/contact_background.8b38804d.png) no-repeat center; }
      .contact.tubos-sem-costura {
        background: #000 url(/static/media/contact_background.5ca001ab.png) no-repeat center; } }
  @media (min-width: 1075px) {
    .contact .box {
      width: 642px;
      margin: 0 auto; } }
  .contact .title-wrapper {
    width: 100%;
    text-align: center; }
  .contact h3 {
    text-align: center;
    font-size: 42px;
    font-weight: bold;
    letter-spacing: 2px;
    padding-bottom: 25px;
    border-bottom: 2px solid #fff;
    margin-bottom: 28px; }
    @media (min-width: 1075px) {
      .contact h3 {
        width: 260px;
        border-bottom: 3px solid #fff;
        margin: 0 auto 30px; } }
  .contact .description {
    text-align: justify;
    font-size: 25px;
    letter-spacing: 1px;
    line-height: 31px;
    padding-bottom: 40px; }
    @media (min-width: 1075px) {
      .contact .description {
        font-size: 21px; } }

@media (min-width: 1075px) {
  .contact-form-wrapper {
    width: 394px;
    margin: 0 auto; } }

.contact-form-wrapper .input-wrapper {
  width: 100%;
  margin: 10px 0; }
  @media (min-width: 1075px) {
    .contact-form-wrapper .input-wrapper {
      margin: 15px 0; } }
  .contact-form-wrapper .input-wrapper input {
    width: 100%;
    height: 66px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 20px;
    color: #676767;
    outline: none;
    padding: 15px; }
    .contact-form-wrapper .input-wrapper input::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #969696;
      opacity: 1;
      /* Firefox */ }
    .contact-form-wrapper .input-wrapper input::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #969696;
      opacity: 1;
      /* Firefox */ }
    .contact-form-wrapper .input-wrapper input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #969696;
      opacity: 1;
      /* Firefox */ }
    .contact-form-wrapper .input-wrapper input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #969696; }
    .contact-form-wrapper .input-wrapper input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #969696; }
    @media (min-width: 1075px) {
      .contact-form-wrapper .input-wrapper input {
        font-size: 14px;
        padding: 10px;
        height: 40px; } }
  .contact-form-wrapper .input-wrapper textarea {
    width: 100%;
    outline: none;
    resize: none;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 15px;
    font-size: 20px; }
    .contact-form-wrapper .input-wrapper textarea::-webkit-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #969696;
      opacity: 1;
      /* Firefox */ }
    .contact-form-wrapper .input-wrapper textarea::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #969696;
      opacity: 1;
      /* Firefox */ }
    .contact-form-wrapper .input-wrapper textarea::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #969696;
      opacity: 1;
      /* Firefox */ }
    .contact-form-wrapper .input-wrapper textarea:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #969696; }
    .contact-form-wrapper .input-wrapper textarea::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #969696; }
    @media (min-width: 1075px) {
      .contact-form-wrapper .input-wrapper textarea {
        font-size: 14px;
        padding: 10px; } }

.contact-form-wrapper .btn-submit {
  width: 100%;
  display: block;
  height: 66px;
  color: #fff;
  font-size: 27px;
  font-weight: bold;
  border: 0;
  border-radius: 5px;
  background: -webkit-linear-gradient(left, #db5f5f, #b94341);
  background: linear-gradient(to right, #db5f5f, #b94341);
  outline: none;
  cursor: pointer; }
  @media (min-width: 1075px) {
    .contact-form-wrapper .btn-submit {
      width: 192px;
      height: 56px;
      font-size: 20px;
      margin: 0 auto; } }

@media (min-width: 1075px) {
  .contact-form-wrapper .two-cols {
    display: flex;
    justify-content: space-between; }
    .contact-form-wrapper .two-cols > .input-wrapper {
      width: 48%;
      margin: 0; } }

.footer {
  width: 100%;
  height: 625px;
  position: relative;
  background-image: -webkit-linear-gradient(#dc5f5d, #b84242);
  background-image: linear-gradient(#dc5f5d, #b84242); }
  @media (min-width: 1075px) {
    .footer {
      height: 492px; } }
  .footer .reminder {
    color: #fff;
    text-align: center; }
    .footer .reminder .title {
      font-size: 25px;
      letter-spacing: 2px;
      font-weight: 300;
      padding-top: 47px; }
      @media (min-width: 1075px) {
        .footer .reminder .title {
          font-size: 22px;
          padding-top: 55px;
          font-weight: normal; } }
    .footer .reminder .product-name {
      font-size: 58px;
      font-weight: 900;
      line-height: 70px;
      letter-spacing: 2px;
      padding-top: 35px;
      position: relative;
      text-transform: uppercase; }
      .footer .reminder .product-name.tubos-com-costura, .footer .reminder .product-name.tubos-sem-costura {
        font-size: 46px;
        line-height: 48px; }
        @media (min-width: 1075px) {
          .footer .reminder .product-name.tubos-com-costura, .footer .reminder .product-name.tubos-sem-costura {
            font-size: 50px; } }
      @media (min-width: 1075px) {
        .footer .reminder .product-name {
          font-size: 60px;
          font-weight: bold; }
          .footer .reminder .product-name:before {
            content: '';
            width: 71px;
            height: 2px;
            background: #fff;
            position: absolute;
            top: 71px;
            margin-left: -92px; }
          .footer .reminder .product-name:after {
            content: '';
            width: 71px;
            height: 2px;
            background: #fff;
            position: absolute;
            top: 71px;
            margin-left: 20px; } }
    .footer .reminder .text {
      text-align: justify;
      text-transform: uppercase;
      font-size: 21px;
      line-height: 30px;
      font-weight: 300;
      padding: 30px 0 0 0; }
      @media (min-width: 1075px) {
        .footer .reminder .text {
          text-align: center;
          font-weight: normal; } }
    .footer .reminder .btn-orcamento {
      margin: 30px auto; }
      @media (min-width: 1075px) {
        .footer .reminder .btn-orcamento {
          width: 330px;
          height: 54px;
          line-height: 54px;
          margin: 60px auto; } }
  .footer .copyright {
    width: 100%;
    color: #fff;
    font-size: 12px;
    text-align: center;
    position: absolute;
    bottom: 52px; }
    @media (min-width: 1075px) {
      .footer .copyright {
        bottom: 32px; } }
    .footer .copyright .powered-by {
      width: 143px;
      display: inline-block;
      position: relative;
      text-align: left; }
      .footer .copyright .powered-by .icon {
        display: inline-block;
        width: 50px;
        height: 10px;
        top: 3px;
        right: 0;
        margin-right: -5px;
        position: absolute;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAAOCAMAAABAZV/bAAAB8lBMVEX///////////////////////////9MaXH/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////ywD/ywD/////////////////////////////////////////////////////ywD/ywD/////////////////ywD/////////ywD/ywD/ywD/1yD/////ywD/ywD/////////////////ywD/////////zQD/////////ywD/////////////////ywD/////2S7/ywD/ywD/////ywD/////ywD/zAD/////////ywD/////////////////ywD/ywD/ywD/ywD/ywD/////ywD/ywD/////ywD/ywD/ywD/////ywD/ywD/ywD/ywD/////0QD/////ywD/ywD/ywD/ywD/ywD/ywD/ywD/ywD/ywD/ywD/////////////////////////zAD/0gD/427/ywD/6pD/0AD/0gD/zQD/ywD//PD/////ywBljWW8AAAApXRSTlOAWGAgMEhAAAgQcDwXeFZaRQNcASQoIwU2CzUEOy4SHC9vDTQfMV4KdlJHCVFbVExVOQInDj5dajAhOGt/Tz8GSgxxQzIlsQN9KmkHw2QbqyQtbiKWgGozLRPcFFDpPREJD1lJZTh6EwYQY4NOZeBuGpdXRnMWaIGquWBEon187lnnKSmLEf50QSaGCuzX8Ppa+ZxEHnVLLE0Z1G8hrRq7jNrVEWy13uUCAAACAUlEQVQ4y3XRZXPbUBSE4aNY0Ss5jhnDzMxpmKnMzMzMbcrMzHz/Z0dy4zTTer9cjWaf0ayuZC/JBabdMw1uO/2D7huEphug3B2fKsVJXxtsq/NpFc0tnuSrwK6t9ZqmeUWkBsgWM0PsZGaJn1cyOUO1jOnHSuzu/dcWxzsKIFTuDfgc3pqTadmnTcJ/uMeyrNqoVOAXmeKXJPTRamAgOGIFKkyHFeX5PfZZXTnP7yaS3GnUShU9InWmDKO7+segpsyw3o4mZxD0+AGzgRTfLb6Qw+01CYqlW073VoXlJLorNxh7/w7DejMELAf8m3whKI3M84K9Ek9tt/BKhtQclKC0o7voPlVfiWHVcfPQxsVAXmFzFHYGyOzQNK1NRCiXq5M2z49EIjGyRKQsz/klugsOF4Hx6dsHpdQyoLFkqI+CQea/Dk0iqe1ERapaNohIJetcycHGz4/flVKrIeYD74BRuICbww6/ZBhGmFqRzdAjxbBojlsTX5VSawg1RmF9fpAFnLLe1L1DsURgRLx/8S+flTqqOj1NOUBsbbvNPaZpmpKv24VVeiCs21kKrfFc2BEvhDMlSX7vh1In9m1ZmWMlLz8BuLLsCP/N9j0H5h4nXo7fUbPXoGvFv700nP1Hzl++cvZi54PxR0o9uXU7TS0dB7rOXbj+VD1//OLZw7Sd3wYzXY44aB0GAAAAAElFTkSuQmCC) no-repeat;
        background-size: contain;
        cursor: pointer; }

.product-specifications-wrapper.barras .product-specifications .box {
  width: 100%;
  background: #fff;
  margin-top: -45px;
  padding: 35px 25px 0;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15); }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.barras .product-specifications .box {
      margin-top: 78px; } }

@media (min-width: 1075px) {
  .product-specifications-wrapper.barras .product-specifications .container {
    padding: 0; } }

.product-specifications-wrapper.barras .product-specifications h3 {
  font-size: 35px;
  color: #e35060;
  text-align: center;
  letter-spacing: 2px;
  padding-bottom: 40px; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.barras .product-specifications h3 {
      font-size: 50px;
      padding-bottom: 50px; } }

.product-specifications-wrapper.barras .product-specifications .specification-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.barras .product-specifications .specification-list {
      flex-wrap: nowrap;
      padding-bottom: 60px; } }

.product-specifications-wrapper.barras .product-specifications .specification-list-item {
  width: 100%;
  text-align: center;
  padding: 5px 0 40px 0; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.barras .product-specifications .specification-list-item {
      height: 270px;
      padding: 0 30px;
      border-right: 1px solid #dedede; }
      .product-specifications-wrapper.barras .product-specifications .specification-list-item:last-child {
        border-right: none; } }
  .product-specifications-wrapper.barras .product-specifications .specification-list-item .icon-wrapper {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center; }
  .product-specifications-wrapper.barras .product-specifications .specification-list-item .icon {
    padding-bottom: 30px;
    align-self: center; }
  .product-specifications-wrapper.barras .product-specifications .specification-list-item .text {
    font-size: 23px;
    line-height: 29px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: justify;
    font-weight: 300;
    color: #676767; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.barras .product-specifications .specification-list-item .text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: normal; } }
    .product-specifications-wrapper.barras .product-specifications .specification-list-item .text p {
      padding: 15px 0; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.barras .product-specifications .specification-list-item .text p {
          padding: 5px 0; } }
      .product-specifications-wrapper.barras .product-specifications .specification-list-item .text p span {
        font-weight: bold; }
      .product-specifications-wrapper.barras .product-specifications .specification-list-item .text p:last-child {
        padding-bottom: 40px;
        border-bottom: 1px solid #dedede; }
        @media (min-width: 1075px) {
          .product-specifications-wrapper.barras .product-specifications .specification-list-item .text p:last-child {
            border-bottom: none; } }
  .product-specifications-wrapper.barras .product-specifications .specification-list-item:last-child p:last-child {
    border-bottom: none; }

.product-specifications-wrapper.blanques .product-specifications .box {
  width: 100%;
  background: #fff;
  margin-top: -45px;
  padding: 35px 25px 0;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15); }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.blanques .product-specifications .box {
      margin-top: 78px; } }

@media (min-width: 1075px) {
  .product-specifications-wrapper.blanques .product-specifications .container {
    padding: 0; } }

.product-specifications-wrapper.blanques .product-specifications h3 {
  font-size: 35px;
  color: #e35060;
  text-align: center;
  letter-spacing: 2px;
  padding-bottom: 40px; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.blanques .product-specifications h3 {
      font-size: 50px;
      padding-bottom: 50px; } }

.product-specifications-wrapper.blanques .product-specifications .specification-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.blanques .product-specifications .specification-list {
      flex-wrap: nowrap;
      padding-bottom: 60px; } }

.product-specifications-wrapper.blanques .product-specifications .specification-list-item {
  width: 100%;
  text-align: center;
  padding: 5px 0 40px 0; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.blanques .product-specifications .specification-list-item {
      height: 270px;
      padding: 0 30px;
      border-right: 1px solid #dedede; }
      .product-specifications-wrapper.blanques .product-specifications .specification-list-item:last-child {
        border-right: none; } }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.blanques .product-specifications .specification-list-item.left {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      .product-specifications-wrapper.blanques .product-specifications .specification-list-item.left .icon-wrapper {
        width: 100px; }
      .product-specifications-wrapper.blanques .product-specifications .specification-list-item.left .text {
        width: 328px;
        font-size: 20px;
        line-height: 25px; } }
  .product-specifications-wrapper.blanques .product-specifications .specification-list-item .title {
    font-size: 42px; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.blanques .product-specifications .specification-list-item .title {
        font-size: 25px;
        margin-bottom: 10px;
        margin-top: 20px; } }
  .product-specifications-wrapper.blanques .product-specifications .specification-list-item ul {
    list-style: disc inside;
    padding-top: 15px; }
    .product-specifications-wrapper.blanques .product-specifications .specification-list-item ul li {
      font-weight: bold;
      font-size: 21px;
      line-height: 30px; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.blanques .product-specifications .specification-list-item ul li {
          font-size: 16px;
          line-height: 26px; } }
  .product-specifications-wrapper.blanques .product-specifications .specification-list-item .icon-wrapper {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center; }
  .product-specifications-wrapper.blanques .product-specifications .specification-list-item .icon {
    padding-bottom: 30px;
    align-self: center; }
  .product-specifications-wrapper.blanques .product-specifications .specification-list-item .text {
    font-size: 23px;
    line-height: 29px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: justify;
    font-weight: 300;
    color: #676767; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.blanques .product-specifications .specification-list-item .text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: normal; } }
    .product-specifications-wrapper.blanques .product-specifications .specification-list-item .text p {
      padding: 15px 0; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.blanques .product-specifications .specification-list-item .text p {
          padding: 5px 0; } }
      .product-specifications-wrapper.blanques .product-specifications .specification-list-item .text p span {
        font-weight: bold; }
      .product-specifications-wrapper.blanques .product-specifications .specification-list-item .text p:last-child {
        padding-bottom: 40px;
        border-bottom: 1px solid #dedede; }
        @media (min-width: 1075px) {
          .product-specifications-wrapper.blanques .product-specifications .specification-list-item .text p:last-child {
            border-bottom: none; } }
  .product-specifications-wrapper.blanques .product-specifications .specification-list-item:last-child p:last-child {
    border-bottom: none; }

.product-specifications-wrapper.bobinas .product-specifications .box {
  width: 100%;
  background: #fff;
  margin-top: -45px;
  padding: 35px 25px 0;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15); }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.bobinas .product-specifications .box {
      margin-top: 78px; } }

@media (min-width: 1075px) {
  .product-specifications-wrapper.bobinas .product-specifications .container {
    padding: 0; } }

.product-specifications-wrapper.bobinas .product-specifications h3 {
  font-size: 35px;
  color: #e35060;
  text-align: center;
  letter-spacing: 2px;
  padding-bottom: 40px; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.bobinas .product-specifications h3 {
      font-size: 50px;
      padding-bottom: 50px; } }

.product-specifications-wrapper.bobinas .product-specifications .specification-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.bobinas .product-specifications .specification-list {
      flex-wrap: nowrap;
      padding-bottom: 60px; } }

.product-specifications-wrapper.bobinas .product-specifications .specification-list-item {
  width: 100%;
  text-align: center;
  padding: 5px 0 40px 0; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.bobinas .product-specifications .specification-list-item {
      height: 270px;
      padding: 0 30px;
      border-right: 1px solid #dedede; }
      .product-specifications-wrapper.bobinas .product-specifications .specification-list-item:last-child {
        border-right: none; } }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.bobinas .product-specifications .specification-list-item.left {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-top: 30px; }
      .product-specifications-wrapper.bobinas .product-specifications .specification-list-item.left .icon-wrapper {
        width: 100px;
        height: 300px;
        display: flex;
        flex-wrap: wrap; }
        .product-specifications-wrapper.bobinas .product-specifications .specification-list-item.left .icon-wrapper img {
          padding: 0 0 30px 0; }
      .product-specifications-wrapper.bobinas .product-specifications .specification-list-item.left .text {
        width: 328px;
        font-size: 15px;
        line-height: 18px; }
        .product-specifications-wrapper.bobinas .product-specifications .specification-list-item.left .text p {
          padding: 0; } }
  .product-specifications-wrapper.bobinas .product-specifications .specification-list-item .title {
    font-size: 42px; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.bobinas .product-specifications .specification-list-item .title {
        font-size: 25px;
        margin-bottom: 10px;
        margin-top: 20px; } }
  .product-specifications-wrapper.bobinas .product-specifications .specification-list-item ul {
    list-style: disc inside;
    padding-top: 15px; }
    .product-specifications-wrapper.bobinas .product-specifications .specification-list-item ul li {
      font-weight: bold;
      font-size: 21px;
      line-height: 30px; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.bobinas .product-specifications .specification-list-item ul li {
          font-size: 16px;
          line-height: 26px; } }
  .product-specifications-wrapper.bobinas .product-specifications .specification-list-item .icon-wrapper {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center; }
  .product-specifications-wrapper.bobinas .product-specifications .specification-list-item .icon {
    padding-bottom: 30px;
    align-self: center; }
  .product-specifications-wrapper.bobinas .product-specifications .specification-list-item .text {
    font-size: 23px;
    line-height: 29px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: justify;
    font-weight: 300;
    color: #676767; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.bobinas .product-specifications .specification-list-item .text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: normal; } }
    .product-specifications-wrapper.bobinas .product-specifications .specification-list-item .text p {
      padding: 15px 0; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.bobinas .product-specifications .specification-list-item .text p {
          padding: 5px 0; } }
      .product-specifications-wrapper.bobinas .product-specifications .specification-list-item .text p span {
        font-weight: bold; }
      .product-specifications-wrapper.bobinas .product-specifications .specification-list-item .text p:last-child {
        padding-bottom: 40px;
        border-bottom: 1px solid #dedede; }
        @media (min-width: 1075px) {
          .product-specifications-wrapper.bobinas .product-specifications .specification-list-item .text p:last-child {
            border-bottom: none; } }
  .product-specifications-wrapper.bobinas .product-specifications .specification-list-item:last-child p:last-child {
    border-bottom: none; }

.product-specifications-wrapper.cantoneiras .product-specifications .box {
  width: 100%;
  background: #fff;
  margin-top: -45px;
  padding: 35px 25px 0;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15); }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.cantoneiras .product-specifications .box {
      margin-top: 78px; } }

@media (min-width: 1075px) {
  .product-specifications-wrapper.cantoneiras .product-specifications .container {
    padding: 0; } }

.product-specifications-wrapper.cantoneiras .product-specifications h3 {
  font-size: 35px;
  color: #e35060;
  text-align: center;
  letter-spacing: 2px;
  padding-bottom: 40px; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.cantoneiras .product-specifications h3 {
      font-size: 50px;
      padding-bottom: 50px; } }

.product-specifications-wrapper.cantoneiras .product-specifications .specification-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.cantoneiras .product-specifications .specification-list {
      flex-wrap: nowrap;
      padding-bottom: 60px; } }

.product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item {
  width: 100%;
  text-align: center;
  padding: 5px 0 40px 0; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item {
      height: 270px;
      padding: 0 30px;
      border-right: 1px solid #dedede; }
      .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item:last-child {
        border-right: none; } }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item.left {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item.left .icon-wrapper {
        width: 100px; }
      .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item.left .text {
        width: 328px;
        font-size: 16px;
        line-height: 25px; } }
  .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item .title {
    font-size: 42px; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item .title {
        font-size: 25px;
        margin-bottom: 10px;
        margin-top: 20px; } }
  .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item ul {
    list-style: disc inside;
    padding-top: 15px; }
    .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item ul li {
      font-weight: bold;
      font-size: 21px;
      line-height: 30px; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item ul li {
          font-size: 16px;
          line-height: 26px; } }
  .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item .icon-wrapper {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center; }
  .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item .icon {
    padding-bottom: 30px;
    align-self: center; }
  .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item .text {
    font-size: 23px;
    line-height: 29px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: justify;
    font-weight: 300;
    color: #676767; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item .text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: normal; } }
    .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item .text p {
      padding: 15px 0; }
      .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item .text p.title {
        color: #e35060;
        font-size: 27px;
        letter-spacing: 1px; }
        @media (min-width: 1075px) {
          .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item .text p.title {
            font-size: 20px; } }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item .text p {
          padding: 5px 0; } }
      .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item .text p span {
        font-weight: bold; }
      .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item .text p:last-child {
        padding-bottom: 40px;
        border-bottom: 1px solid #dedede; }
        @media (min-width: 1075px) {
          .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item .text p:last-child {
            border-bottom: none; } }
  .product-specifications-wrapper.cantoneiras .product-specifications .specification-list-item:last-child p:last-child {
    border-bottom: none; }

.product-specifications-wrapper.chapa .product-specifications .box {
  width: 100%;
  background: #fff;
  margin-top: -45px;
  padding: 35px 25px 0;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15); }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.chapa .product-specifications .box {
      margin-top: 78px; } }

@media (min-width: 1075px) {
  .product-specifications-wrapper.chapa .product-specifications .container {
    padding: 0; } }

.product-specifications-wrapper.chapa .product-specifications h3 {
  font-size: 35px;
  color: #e35060;
  text-align: center;
  letter-spacing: 2px;
  padding-bottom: 40px; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.chapa .product-specifications h3 {
      font-size: 50px;
      padding-bottom: 50px; } }

.product-specifications-wrapper.chapa .product-specifications .specification-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.chapa .product-specifications .specification-list {
      flex-wrap: nowrap;
      padding-bottom: 60px; } }

.product-specifications-wrapper.chapa .product-specifications .specification-list-item {
  width: 100%;
  text-align: center;
  padding: 5px 0 40px 0; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.chapa .product-specifications .specification-list-item {
      height: 270px;
      padding: 0 30px;
      border-right: 1px solid #dedede; }
      .product-specifications-wrapper.chapa .product-specifications .specification-list-item:last-child {
        border-right: none; } }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.chapa .product-specifications .specification-list-item.left {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      .product-specifications-wrapper.chapa .product-specifications .specification-list-item.left .icon-wrapper {
        width: 100px;
        height: 300px;
        display: flex;
        flex-wrap: wrap; }
        .product-specifications-wrapper.chapa .product-specifications .specification-list-item.left .icon-wrapper img {
          padding: 0 0 30px 0; }
      .product-specifications-wrapper.chapa .product-specifications .specification-list-item.left .text {
        width: 328px;
        font-size: 14px;
        line-height: 18px !important; }
        .product-specifications-wrapper.chapa .product-specifications .specification-list-item.left .text p {
          padding: 8px 0 !important; } }
  .product-specifications-wrapper.chapa .product-specifications .specification-list-item .title {
    font-size: 42px; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.chapa .product-specifications .specification-list-item .title {
        font-size: 25px;
        margin-bottom: 10px;
        margin-top: 20px; } }
  .product-specifications-wrapper.chapa .product-specifications .specification-list-item ul {
    list-style: disc inside;
    padding-top: 15px; }
    .product-specifications-wrapper.chapa .product-specifications .specification-list-item ul li {
      font-weight: bold;
      font-size: 21px;
      line-height: 30px;
      letter-spacing: 1px; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.chapa .product-specifications .specification-list-item ul li {
          font-size: 16px;
          line-height: 26px; } }
  .product-specifications-wrapper.chapa .product-specifications .specification-list-item .icon-wrapper {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center; }
  .product-specifications-wrapper.chapa .product-specifications .specification-list-item .icon {
    padding-bottom: 30px;
    align-self: center; }
  .product-specifications-wrapper.chapa .product-specifications .specification-list-item .text {
    font-size: 23px;
    line-height: 29px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: justify;
    font-weight: 300;
    color: #676767; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.chapa .product-specifications .specification-list-item .text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: normal; } }
    .product-specifications-wrapper.chapa .product-specifications .specification-list-item .text p {
      padding: 15px 0; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.chapa .product-specifications .specification-list-item .text p {
          padding: 5px 0; } }
      .product-specifications-wrapper.chapa .product-specifications .specification-list-item .text p span {
        font-weight: bold; }
      .product-specifications-wrapper.chapa .product-specifications .specification-list-item .text p:last-child {
        padding-bottom: 40px;
        border-bottom: 1px solid #dedede; }
        @media (min-width: 1075px) {
          .product-specifications-wrapper.chapa .product-specifications .specification-list-item .text p:last-child {
            border-bottom: none; } }
  .product-specifications-wrapper.chapa .product-specifications .specification-list-item:last-child p:last-child {
    border-bottom: none; }

.product-specifications-wrapper.chapa-grossa .product-specifications .box {
  width: 100%;
  background: #fff;
  margin-top: -45px;
  padding: 35px 25px 0;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15); }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.chapa-grossa .product-specifications .box {
      margin-top: 78px; } }

@media (min-width: 1075px) {
  .product-specifications-wrapper.chapa-grossa .product-specifications .container {
    padding: 0; } }

.product-specifications-wrapper.chapa-grossa .product-specifications h3 {
  font-size: 35px;
  color: #e35060;
  text-align: center;
  letter-spacing: 2px;
  padding-bottom: 40px; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.chapa-grossa .product-specifications h3 {
      font-size: 50px;
      padding-bottom: 50px; } }

.product-specifications-wrapper.chapa-grossa .product-specifications .specification-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list {
      flex-wrap: nowrap;
      padding-bottom: 60px; } }

.product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item {
  width: 100%;
  text-align: center;
  padding: 5px 0 40px 0; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item {
      height: 270px;
      padding: 0 30px;
      border-right: 1px solid #dedede; }
      .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item:last-child {
        border-right: none; } }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item.left {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-top: 0; }
      .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item.left .icon-wrapper {
        width: 100px; }
      .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item.left .text {
        width: 328px;
        font-size: 20px;
        line-height: 25px; } }
  .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item .title {
    font-size: 42px; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item .title {
        font-size: 25px;
        margin-bottom: 10px;
        margin-top: 20px; } }
  .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item ul {
    list-style: disc inside;
    padding-top: 15px; }
    .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item ul li {
      font-weight: bold;
      font-size: 21px;
      line-height: 30px; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item ul li {
          font-size: 16px;
          line-height: 26px; } }
  .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item .icon-wrapper {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center; }
  .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item .icon {
    padding-bottom: 30px;
    align-self: center; }
  .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item .text {
    font-size: 23px;
    line-height: 29px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: justify;
    font-weight: 300;
    color: #676767; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item .text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: normal; } }
    .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item .text p {
      padding: 15px 0; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item .text p {
          padding: 5px 0; } }
      .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item .text p span {
        font-weight: bold; }
      .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item .text p:last-child {
        padding-bottom: 40px;
        border-bottom: 1px solid #dedede; }
        @media (min-width: 1075px) {
          .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item .text p:last-child {
            border-bottom: none; } }
  .product-specifications-wrapper.chapa-grossa .product-specifications .specification-list-item:last-child p:last-child {
    border-bottom: none; }

.product-specifications-wrapper.fita-tiras .product-specifications .box {
  width: 100%;
  background: #fff;
  margin-top: -45px;
  padding: 35px 25px 0;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15); }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.fita-tiras .product-specifications .box {
      margin-top: 78px; } }

@media (min-width: 1075px) {
  .product-specifications-wrapper.fita-tiras .product-specifications .container {
    padding: 0; } }

.product-specifications-wrapper.fita-tiras .product-specifications h3 {
  font-size: 35px;
  color: #e35060;
  text-align: center;
  letter-spacing: 2px;
  padding-bottom: 40px; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.fita-tiras .product-specifications h3 {
      font-size: 50px;
      padding-bottom: 50px; } }

.product-specifications-wrapper.fita-tiras .product-specifications .specification-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.fita-tiras .product-specifications .specification-list {
      flex-wrap: nowrap;
      padding-bottom: 60px; } }

.product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item {
  width: 100%;
  text-align: center;
  padding: 5px 0 40px 0; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item {
      height: 270px;
      padding: 0 30px;
      border-right: 1px solid #dedede; }
      .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item:last-child {
        border-right: none; } }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item.left {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item.left .icon-wrapper {
        width: 100px; }
      .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item.left .text {
        width: 328px;
        font-size: 20px;
        line-height: 25px; } }
  .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item .title {
    font-size: 42px;
    color: #636363 !important;
    font-weight: bold; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item .title {
        font-size: 29px !important;
        line-height: 29px !important;
        margin-bottom: 10px;
        margin-top: 20px;
        color: #636363 !important;
        font-weight: normal !important; } }
  .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item ul {
    list-style: disc inside;
    padding-top: 15px; }
    .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item ul li {
      font-weight: bold;
      font-size: 21px;
      line-height: 30px; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item ul li {
          font-size: 16px;
          line-height: 26px; } }
  .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item .icon-wrapper {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center; }
  .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item .icon {
    padding-bottom: 30px;
    align-self: center; }
  .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item .text {
    font-size: 23px;
    line-height: 29px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: justify;
    font-weight: 300;
    color: #676767; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item .text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: normal; } }
    .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item .text p {
      padding: 15px 0; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item .text p {
          padding: 5px 0; } }
      .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item .text p span {
        font-weight: bold; }
      .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item .text p:last-child {
        padding-bottom: 40px;
        border-bottom: 1px solid #dedede; }
        @media (min-width: 1075px) {
          .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item .text p:last-child {
            border-bottom: none; } }
  .product-specifications-wrapper.fita-tiras .product-specifications .specification-list-item:last-child p:last-child {
    border-bottom: none; }

.product-specifications-wrapper.tubos-com-costura .product-specifications .box {
  width: 100%;
  background: #fff;
  margin-top: -45px;
  padding: 35px 25px 0;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15); }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.tubos-com-costura .product-specifications .box {
      margin-top: 78px; } }

@media (min-width: 1075px) {
  .product-specifications-wrapper.tubos-com-costura .product-specifications .container {
    padding: 0; } }

.product-specifications-wrapper.tubos-com-costura .product-specifications h3 {
  font-size: 35px;
  color: #e35060;
  text-align: center;
  letter-spacing: 2px;
  padding-bottom: 40px; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.tubos-com-costura .product-specifications h3 {
      font-size: 50px;
      padding-bottom: 50px; } }

.product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list {
      flex-wrap: nowrap;
      padding-bottom: 60px; } }

.product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item {
  width: 100%;
  text-align: center;
  padding: 5px 0 40px 0; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item {
      height: 270px;
      padding: 0 30px;
      border-right: 1px solid #dedede; }
      .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item:last-child {
        border-right: none; } }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item.left {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item.left .icon-wrapper {
        width: 100px;
        padding: 0 30px !important; }
        .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item.left .icon-wrapper img {
          padding-left: 50px; }
      .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item.left .text {
        width: 300px;
        font-size: 14px;
        line-height: 21px; } }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item.right {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item.right .icon-wrapper {
        width: 100px;
        padding: 0 30px !important; }
        .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item.right .icon-wrapper img {
          padding-top: 20px;
          padding-left: 50px; }
      .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item.right .text {
        width: 300px;
        font-size: 14px;
        line-height: 21px; } }
  .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item .title {
    font-size: 30px;
    color: #e35060 !important;
    padding-bottom: 60px;
    text-transform: uppercase; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item .title {
        width: 100%;
        font-size: 22px !important;
        color: #e35060 !important;
        text-transform: uppercase;
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: 30px; } }
  .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item ul {
    list-style: disc inside;
    padding-top: 15px; }
    .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item ul li {
      font-weight: bold;
      font-size: 21px;
      line-height: 30px; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item ul li {
          font-size: 16px;
          line-height: 26px; } }
  .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item .icon-wrapper {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center; }
  .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item .icon {
    padding-bottom: 30px;
    align-self: center; }
  .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item .text {
    font-size: 23px;
    line-height: 29px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: justify;
    font-weight: 300;
    color: #676767; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item .text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: normal; } }
    .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item .text p {
      padding: 15px 0; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item .text p {
          padding: 5px 0; } }
      .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item .text p span {
        font-weight: bold; }
      .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item .text p:last-child {
        padding-bottom: 40px;
        border-bottom: 1px solid #dedede; }
        @media (min-width: 1075px) {
          .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item .text p:last-child {
            border-bottom: none; } }
  .product-specifications-wrapper.tubos-com-costura .product-specifications .specification-list-item:last-child p:last-child {
    border-bottom: none; }

.product-specifications-wrapper.tubos-sem-costura .product-specifications .box {
  width: 100%;
  background: #fff;
  margin-top: -45px;
  padding: 35px 25px 0;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.15); }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.tubos-sem-costura .product-specifications .box {
      margin-top: 78px; } }

@media (min-width: 1075px) {
  .product-specifications-wrapper.tubos-sem-costura .product-specifications .container {
    padding: 0; } }

.product-specifications-wrapper.tubos-sem-costura .product-specifications h3 {
  font-size: 35px;
  color: #e35060;
  text-align: center;
  letter-spacing: 2px;
  padding-bottom: 40px; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.tubos-sem-costura .product-specifications h3 {
      font-size: 50px;
      padding-bottom: 50px; } }

.product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list {
      flex-wrap: nowrap;
      padding-bottom: 60px; } }

.product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item {
  width: 100%;
  text-align: center;
  padding: 5px 0 40px 0; }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item {
      height: 270px;
      padding: 0 30px;
      border-right: 1px solid #dedede; }
      .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item:last-child {
        border-right: none; } }
  @media (min-width: 1075px) {
    .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item.left {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
      .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item.left .icon-wrapper {
        width: 100px; }
      .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item.left .text {
        width: 328px;
        font-size: 20px;
        line-height: 25px; } }
  .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item .title {
    font-size: 42px; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item .title {
        font-size: 25px;
        margin-bottom: 10px;
        margin-top: 20px; } }
  .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item ul {
    list-style: disc inside;
    padding-top: 15px; }
    .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item ul li {
      font-weight: bold;
      font-size: 21px;
      line-height: 30px; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item ul li {
          font-size: 16px;
          line-height: 26px; } }
  .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item .icon-wrapper {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    padding: 20px 0; }
    .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item .icon-wrapper img {
      padding: 20px 0; }
  .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item .icon {
    padding-bottom: 30px;
    align-self: center; }
  .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item .text {
    font-size: 23px;
    line-height: 29px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: justify;
    font-weight: 300;
    color: #676767;
    margin-top: 30px; }
    @media (min-width: 1075px) {
      .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item .text {
        font-size: 15px !important;
        line-height: 20px;
        letter-spacing: 1px;
        margin-top: 0; } }
    .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item .text p {
      padding: 15px 0; }
      @media (min-width: 1075px) {
        .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item .text p {
          padding: 5px 0; } }
      .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item .text p span {
        font-weight: bold; }
      .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item .text p:last-child {
        padding-bottom: 40px;
        border-bottom: 1px solid #dedede; }
        @media (min-width: 1075px) {
          .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item .text p:last-child {
            border-bottom: none; } }
  .product-specifications-wrapper.tubos-sem-costura .product-specifications .specification-list-item:last-child p:last-child {
    border-bottom: none; }

